import { createSlice } from "@reduxjs/toolkit";

const initialCategoriesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    lastError: null
};

export const callTypes = {
    list: "list",
    action: "action"
}

export const skuListSlice = createSlice({
    name: "skuList",
    initialState: initialCategoriesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        skusFetched: (state, action) => {
            const { totalCount, entities, CategoryList, quickFiltersList = [] } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities.map(ele => ({...ele,
                skuId: ele.skuId ?? '',
                skuName: ele.skuName ?? '',
                categories: ele.categories?.map(category => CategoryList?.find(cat => (cat.CategoryId ? cat.CategoryId : cat.key) == category)?.CategoryName)?.filter(category => category)?.join(', ') ?? [],
                quickFilters: ele.quickFilters?.map(filter => quickFiltersList.find(qf => qf.value == filter)?.QuickFilterName)?.join(', ') ?? [],
                lastUpdatedBy: ele.lastUpdatedBy ?? '',
                lastUpdatedOn: ele.lastUpdatedOn ?? '',
                corpSkuStatus: ele.corpSkuStatus ?? false,
                status: ele.corpSkuStatus ? 'Active' : 'Inactive',
                seconds: ele.lastUpdatedOn ? ele.lastUpdatedOn.toDate().getTime() : '',
            })).sort((a,b) => Number(b.seconds) - Number(a.seconds)).map(ele => ({...ele,
            updatedOn: `${ele.lastUpdatedOn.toDate().toDateString()}, ${ele.lastUpdatedOn.toDate().toLocaleTimeString()}`}));
            state.totalCount = totalCount;
          },
          skuDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(ele => ele.key != action.payload.skuId);
        },
    }
})