import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  loading: false,
  actionLoading: false,
  actionCompleted: false,
  userDetails: null,
  error: "",
  successMsg: "",
  dropDownDetails: [],
  updatedDropdownOptions: false,
};

export const addUserSlice = createSlice({
  name: "addUser",
  initialState: initialUserState,
  reducers: {
    setDropdownDetails: (state, action) => {
      state.loading = false;
      state.error = null;
      state.dropDownDetails = action.payload;
      state.updatedDropdownOptions = true;
    },
    resetState: (state, action) => {
      state.loading = initialUserState.loading;
      state.actionLoading = initialUserState.actionLoading;
      state.actionCompleted = initialUserState.actionCompleted;
      state.userDetails = initialUserState.userDetails;
      state.error = initialUserState.error;
      state.successMsg = initialUserState.successMsg;
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    startActionCall: (state, action) => {
      state.error = null;
      state.actionLoading = true;
      state.actionCompleted = false;
    },
    setUSERDetails: (state, action) => {
      state.loading = false;
      state.error = null;
      state.userDetails = action.payload.details;
    },
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loading = false;
      state.actionLoading = false;
    },
    onAddUpdateUser: (state, action) => {
      state.actionLoading = false;
      state.actionCompleted = true;
      state.successMsg = action.payload.successMsg;
      state.userDetails = initialUserState.userDetails;
    },
    onGetDropdownFailure: (state, action) => {
      state.updatedDropdownOptions = true;
    },
  },
});
