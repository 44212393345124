import { createSlice } from "@reduxjs/toolkit";

const initialSKUState = {
    loading: false,
    actionLoading: false,
    actionCompleted: false,
    skuDetails: null,
    error: '',
    CategoryList: [],
    PackOptions: [],
    UnitsOptions: [],
    // skusList: [],
    updatedDropdownOptions: false,
    successMsg: '',
    Products: [],
    quickFiltersList: []
};

export const addSkuSlice = createSlice({
    name: "addSku",
    initialState: initialSKUState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.loading = false;
            state.actionLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },
        startActionCall: (state, action) => {
            state.error = null;
            state.actionLoading = true;
            state.actionCompleted = false
        },
        setSKUDetails: (state, action) => {
            state.loading = false;
            state.error = null;
            state.skuDetails = action.payload.details
        },
        onAddUpdateSuccess: (state, action) => {
            state.actionLoading = false;
            state.actionCompleted = true;
            state.successMsg = action.payload.successMsg;
            state.skuDetails = initialSKUState.skuDetails;
            // state.skusList = !action.payload.skuDetails.id ? [...state.skusList, action.payload.skuDetails] : state.skusList;
        },
        resetState: (state, action) => {
            state.loading = initialSKUState.loading;
            state.actionLoading = initialSKUState.actionLoading;
            state.actionCompleted = initialSKUState.actionCompleted;
            state.skuDetails = initialSKUState.skuDetails;
            state.error = initialSKUState.error;
            state.successMsg = initialSKUState.successMsg;
        },
        setDropdownDetails: (state, action) => {
            state.CategoryList = Array.isArray(action.payload.CategoryList) ? action.payload.CategoryList.map(ele => ({ ...ele, value: ele.CategoryId ? ele.CategoryId : ele.key, label: ele.CategoryName })) : [];
            state.PackOptions = Array.isArray(action.payload.ProductFieldOption?.ProductPackTypes) ? action.payload.ProductFieldOption?.ProductPackTypes : [];
            let unitKeys = Object.keys(action.payload.ProductFieldOption?.Units);
            state.UnitsOptions = unitKeys.length ?
                unitKeys.map(ele => ({ label: action.payload.ProductFieldOption?.Units[ele], value: action.payload.ProductFieldOption?.Units[ele] })) : [];
            // state.skusList = action.payload.skusList.sort((a, b) => a.a - b.a)
            state.Products = action.payload.Products.map(ele => ({...ele, label: ele.prdName, value: ele.prdId })).sort((a,b) => Number(a.prdId) - Number(b.prdId));
            state.quickFiltersList = action.payload.quickFiltersList.map(ele => ({...ele, label: ele.QuickFilterName }))
            state.updatedDropdownOptions = true;
        },
        onGetDropdownFailure: (state, action) => {
            state.updatedDropdownOptions = true
        },
        addNewProduct: (state, action) => {
            state.Products.push({ ...action.payload, value: action.payload.prdId, label: action.payload.prdName })
        }   
    }
})