import { createSlice } from "@reduxjs/toolkit";

const initialStoresState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    storeForEdit: {StoreName:"",QuickFilters:"",Status:""},
    lastError: null
};

export const callTypes = {
    list: "list",
    action: "action"
}

export const storesSlice = createSlice({
    name: "stores",
    initialState: initialStoresState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startActionCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
            state.actionCompleted = false
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        storesFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.storeForEdit = action.payload.storeForEdit;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
          },
          quickFiltersFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.quickFilters = entities;
          },
           // getCustomerById
        storeFetched: (state, action) => {
            state.actionsLoading = false;
            state.storeForEdit = action.payload.storeForEdit;
            state.error = null;
        },
        
        onAddUpdateSuccess: (state, action) => {
            state.actionsLoading = false;
            state.actionCompleted = true;
            state.successMsg = action.payload.successMsg;
            state.storeDetails = initialStoresState.storeDetails;
        },
        storeDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // state.entities = state.entities.filter(
            //     el => !action.payload.ids.includes(el.id)
            // );
        },
    }
})