import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const CategoryPage = lazy(() =>
  import("./modules/Category/CategoryPage")
);
const StorePage = lazy(() =>
  import("./modules/Store/StoresPage")
);
const AddStore = lazy(() =>
  import("./modules/Store/AddStore")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const SKUsList = lazy(() => import("./modules/SKUList/SkuList"));
const AddSKU = lazy(() => import("./modules/AddSKU/AddSKU"));
const AddUSER = lazy(() => import("./modules/AddUSER/AddUser"));
const USERsList = lazy(() => import("./modules/USERList/UserList"))
const AOIncoming = lazy(() => import("./modules/AOIncoming/AOIncoming"))
const AOOutgoing = lazy(() => import("./modules/AOOutgoing/AOOutgoing"))
const AOCurrentInventory = lazy(() => import("./modules/AOCurrentInventory/AOCurrentInventory"))
const StoreCurrentInventory = lazy(() => import("./modules/StoreCurrentInventory/StoreCurrentInventory"))

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/inventory/categories" component={CategoryPage} />
        <Route path="/inventory/stores" component={StorePage} />
        <Route path="/inventory/addstores" component={AddStore} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/inventory/skusList" component={SKUsList} />
        <Route path="/inventory/addSkus" component={AddSKU} />
        <Route path="/users/addUser" component={AddUSER} />
        <Route path="/users/usersList" component={USERsList} />
        <Route path="/adminInventory/aoIncoming" component={AOIncoming} />
        <Route path="/adminInventory/aoOutgoing" component={AOOutgoing} />
        <Route path="/adminInventary/aoCurrentInventory" component={AOCurrentInventory} />
        <Route path="/adminInventary/storeCurrentInventory" component={StoreCurrentInventory} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
