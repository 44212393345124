import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { categoriesSlice } from "../app/modules/Category/_redux/categoriesSlice";
import { storesSlice } from "../app/modules/Store/_redux/storesSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { skuListSlice } from "../app/modules/SKUList/_redux/reducer";
import { addSkuSlice } from "../app/modules/AddSKU/_redux/reducer";
import { addUserSlice } from "../app/modules/AddUSER/_redux/reducer";
import { userListSlice } from "../app/modules/USERList/_redux/reducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  categories: categoriesSlice.reducer,
  stores: storesSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  skusList: skuListSlice.reducer,
  addSku: addSkuSlice.reducer,
  addUser: addUserSlice.reducer,
  userList: userListSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
