import { createSlice } from "@reduxjs/toolkit";

const initialCategoriesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const userListSlice = createSlice({
  name: "userList",
  initialState: initialCategoriesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    usersFetched: (state, action) => {
      const {
        totalCount,
        entities,
      } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities.map((ele) => ({
        ...ele,
        empCP: `${ele?.empCP} ${ele?.contractType ?? ''}` ?? "",
        lastUpdatedBy: ele.lastUpdatedBy ?? "",
        lastUpdatedOn: ele.lastUpdatedOn ?? "",
      }))
      state.totalCount = totalCount;
    },
    skuDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (ele) => ele.key != action.payload.empId
      );
    },
  },
});
